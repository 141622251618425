<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center"
        >
          <h5 class="d-inline mr-2 font-weight-bold" style="width: 300px">
            {{ "ГЧП книга" }}
          </h5>

          <div class="text-center d-flex sorddata ml-3" style="width: 100%">
            <el-date-picker
              class="ml-4"
              style="width: 10%"
              size="medium"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              class="ml-4"
              style="width: 10%"
              size="medium"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
          <el-button
            type="success"
            size="medium"
            class="ml-4"
            @click="printExcel"
            icon="el-icon-download"
            style="width: 20%"
            :loading="loadingButton"
            :disabled="loadingButton ? true : false"
          >
            Скачать
          </el-button>
        </div>

        <div
          class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center mt-5"
        >
          <h5 class="d-inline mr-2 font-weight-bold" style="width: 300px">
            {{ "363 форма 014-2" }}
          </h5>

          <div class="text-center d-flex sorddata ml-3" style="width: 100%">
            <el-date-picker
              class="ml-4"
              style="width: 10%"
              size="medium"
              v-model="filterForm2.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              class="ml-4"
              style="width: 10%"
              size="medium"
              v-model="filterForm2.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
          <el-button
            type="success"
            size="medium"
            class="ml-4"
            @click="printExcel2"
            icon="el-icon-download"
            style="width: 20%"
            :loading="loadingButton2"
            :disabled="loadingButton2 ? true : false"
          >
            Скачать
          </el-button>
        </div>

        <div
          class="col-10 p-0 align-self-center font-weight-bold d-flex align-items-center mt-5"
        >
          <h5 class="d-inline mr-2 font-weight-bold" style="width: 12.5%">
            {{ "Полный отчет" }}
          </h5>

          <div class="text-center d-flex sorddata ml-3" style="width: 44.5%">
            <el-date-picker
              class="ml-4"
              style="width: 10%"
              size="medium"
              v-model="filterForm3.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            />
            <el-date-picker
              class="ml-4"
              style="width: 10%"
              size="medium"
              v-model="filterForm3.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            />
          </div>
          <el-button
            type="success"
            size="medium"
            class="ml-4"
            @click="printExcel3"
            icon="el-icon-download"
            style="width: 10%"
            :loading="loadingButton3"
            :disabled="loadingButton3 ? true : false"
          >
            Скачать
          </el-button>
          <div class="col-3 d-inline mr-2 font-weight-bold">
            <el-statistic
              v-if="timer_show"
              :value="deadline"
              format="mm:ss"
              time-indices
            >
              <template slot="suffix">
                (ожидайте, идёт сбор информации)
              </template>
            </el-statistic>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      data: null,
      deadline: 0,
      timer_show: false,
      filterForm: {
        start_date: null,
        end_date: null,
      },
      filterForm2: {
        start_date: null,
        end_date: null,
      },
      filterForm3: {
        start_date: null,
        end_date: null,
      },
      loadingButton: false,
      loadingButton2: false,
      loadingButton3: false,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      excelReport: "orders/excelReport",
      excelReport2: "orders/excelReport2",
      excelReport3: "orders/excelReport3",
    }),
    printExcel() {
      this.loadingButton = true;
      this.excelReport(this.filterForm)
        .then((res) => {
          this.loadingButton = false;
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ГЧП книга.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.loadingButton = false;
          err.text().then((text) => {
            let error = JSON.parse(text);
            if (error.code == 422) {
              this.$notify({
                title: "Ошибка ",
                type: "error",
                position: "bottom right",
                message: error.error.message,
              });
            } else {
              this.$alert(error);
            }
          });
        });
    },

    printExcel2() {
      this.loadingButton2 = true;
      this.excelReport2(this.filterForm2)
        .then((res) => {
          this.loadingButton2 = false;
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "363 форма 014-2.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.loadingButton2 = false;
          err.text().then((text) => {
            let error = JSON.parse(text);
            if (error.code == 422) {
              this.$notify({
                title: "Ошибка ",
                type: "error",
                position: "bottom right",
                message: error.error.message,
              });
            } else {
              this.$alert(error);
            }
          });
        });
    },

    printExcel3() {
      this.loadingButton3 = true;
      this.deadline = Date.now() + 300000;
      this.timer_show = true;
      this.$notify({
        title: 'Подождите',
        message: 'Отчёт собирается',
        type: 'warning',
        duration: 0
      });
      this.excelReport3(this.filterForm3)
        .then((res) => {
          this.timer_show = false;
          this.loadingButton3 = false;
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Полный отчет.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.loadingButton3 = false;
          err.text().then((text) => {
            let error = JSON.parse(text);
            if (error.code == 422) {
              this.$notify({
                title: "Ошибка ",
                type: "error",
                position: "bottom right",
                message: error.error.message,
              });
            } else {
              this.$alert(error);
            }
          });
        });
    },
  },
};
</script>
